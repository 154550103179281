import * as React from "react";

const NotFoundPage = () => (
  <div className="content-container--1200 not-found-content">
    <h1>404: Not Found</h1>
    <p>
      Unfortunately the page you are trying to access does not exist.{" "}
      <a href="/">Please visit our homepage</a>.
    </p>
  </div>
);

export default NotFoundPage;
